.medyaDeneyimiRadioGroup{
  margin-top:20px;
  display:flex;

}

.linkInfo{
  font-size:12px;
  font-style: italic;
  color:#777;
  padding:5px;
}

.baglantiWrapper{
  display:flex;
  align-items: center;
}

.buttonBaglantiEkle{
  margin-left:5px;
  background:#80bd01;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  border:none !important;
}

.gorus-konular{
  min-width: 350px;
}
.componentWrapper{
  margin-top:0px;
}

.step4GorusKonular{
  margin-top:15px;
}