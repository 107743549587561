.buttonBaglantiEkle{
  margin-left:5px;
  background:#80bd01;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  border:none !important;
}