.mainSection{
  
}

.sectionWrapper{
  padding-top:30px !important;
  max-width: 1200px !important;
}

.componentContainer{
  margin-top:30px;
  width:100%;
  max-width:900px;
  background: #fff;
  border-radius: 10px;
  // box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
  display:block;
  min-height:200px;
}

.gridWrapper{
  width:100%;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;

  .sectionTitle{
    text-align:center;
    font-size: 17px;
    font-weight: 500;
  }
}

.gridWrapper > div{
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-left:10px;
  padding-right:10px;
}

.componentWrapper{
  margin-top:20px;
}

.contentInfoWrapper{
  padding:7px;
  display:flex;
  flex-direction: column;
  height:100%;
  max-width:350px;
  min-width: 300px;
  width:100%;
  max-height: 400px;
  overflow-y:auto;
  border:1px solid #ebebeb;
  border-radius: 5px;
  // box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);


  fast-checkbox{
    margin-top:5px;
    margin-bottom:5px;
  }

  .contentInfo{
    padding-top:0px;
    font-size:15px;
    margin-bottom:0px;
  }

  .edits{
    padding:7px;
    font-size:15px;
    max-width: 300px !important;
  }

  .react-dropdown-select{
    margin-top:10px;
    border-radius: 5px;
    border:1px solid #80bd01;
    outline-color: #000 !important;
  }

  .react-dropdown-select-dropdown{
    border-radius: 5px;  
    font-size:15px;
  }


}


.gorus-konular,.ulkeler-bolgeler{
  padding-left:20px;
  padding-right:20px;
  min-height: 400px;
}

.gorus-konular{
  max-height: unset !important;
  height:auto !important;
  min-height:400px !important;
  overflow-y: unset !important;


}


.editSubject{
  margin-top:10px;
}
.tooManyLetters{
  color:red;
}
.customSelect{
  padding:10px;
  
}

.mainSection3{
  .componentWrapper {
    margin-top:15px !important;
  }
}