.imageFigure{
  display:flex;
  justify-content: center;
  align-items: center; 
  position: relative;
  margin-bottom:50px;
  
}
.iconFemale{
  height:92px;
  width:86px;
  stroke:#8e8e8e;
  display: block;
  position: absolute;
  left:0;
  right:1px;
  top:0;
  bottom:5px;
  margin:auto;
}

.imageToolbar{
  position: relative;
  height:30px;
  width: 200px;
  display:flex;
  justify-content: center;
  margin-top:-40px;
  margin-bottom:50px;
}
.buttonTransparent{
  background:none;
  border:none;
  width:50px;
  height:50px;
  cursor: pointer;
}

.buttonUpload{
  background:none;
  border:none;
  width:150px;
  height:30px;
  border-radius: 20px;
  cursor: pointer;
  background:#1e5c37;
  color:#fff;
  margin-top:40px;
}
.titleAdsoyad{
  margin-top:60px;
}


.img-remove{
  width:30px;
}
.img-upload{
  width: 30px;
}

  