/* The radioContainer */
.radioContainer {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: relative;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkRadio {
  position: absolute;
  left:0px;
  width: calc(((var(--base-height-multiplier) + var(--density)) * var(--design-unit) / 2 + var(--design-unit)) * 1px);
  height: calc(((var(--base-height-multiplier) + var(--density)) * var(--design-unit) / 2 + var(--design-unit)) * 1px);
  box-sizing: border-box;
  border-radius: calc(var(--corner-radius) * 0px);
  border: calc(var(--outline-width) * 1px) solid var(--neutral-outline-rest);
  background: var(--neutral-fill-input-rest);
  outline: none;
  cursor: pointer;
  border-radius: 50%;
}

// /* On mouse-over, add a grey background color */
// .radioContainer:hover input ~ .checkmarkRadio {
//   background-color: #ccc;
// }

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmarkRadio {
  background-color: #80bd01;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkRadio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmarkRadio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmarkRadio:after {
 	top: 5px;
	left: 5px;
	width: 55%;
	height: 55%;
	border-radius: 80%;
	background: white;
}

.checked-indicator-radio {
  position: absolute;
  inset: 5px;
  border-radius: 999px;
  display: inline-block;
  background: var(--accent-foreground-cut-rest);
  fill: var(--accent-foreground-cut-rest);
  opacity: 0;
  pointer-events: none;
}