* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color:#404040;
}
fast-design-system-provider {
  display: block;
}


main{
  height:100vh;
  display:grid;
  grid-template-rows:150px 1fr 100px;
  padding-top:10px;
}

.mainSection{
  display:flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.sectionWrapper{
  max-width: 1074px;
  width: 100%;
  height:100%;
  position:relative;
  padding-top:0px;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.girisYazi{
  font-size:19px;
  font-weight: 400;
  color:#404040;
  line-height: 30px;
  margin-top:40px;
  margin-bottom:40px;
}

.sectionTitle{
  color:#3d3d3d;
  font-size:24px;
  font-weight: 500;
}

.imageFigure{
  border-radius: 50%;
  background:#f1f1f1;
  width:152px;
  height:152px;
  display:block;
  border:3px solid #80bd01;
  margin-top:20px;
  margin-bottom:20px;
}
.edits{
  height:40px;
  width:100%;
  max-width: 400px;
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 5px;
  border:1px solid #80bd01;
  margin-top:20px;
  font-size:17px;
  margin-bottom:20px;
}

.memos{
  height:300px;
  width:100%;
  
  padding:5px;
  padding-left:15px;
  padding-right:15px;
  border-radius: 5px;
  border:1px solid #80bd01;
  margin-top:20px;
  font-size:17px;
  margin-bottom:20px;
}