header{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

header > h1{
  color:#1e5c37;
  width: 400px;
  font-weight: 400;
  align-self: center;
  margin-bottom:0px;
  margin-top:10px;

}

.navbar{
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 257px));
  grid-gap: 23px;
  grid-template-rows:50px 1fr;
  justify-content: center;
  align-items: center;
}

nav > div{
  width:100%;
  height:52px;
  background:#f1f1f1;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleColor{
  color:#80bd01;
}
.nav-links{
  text-decoration: none;
  font-size:24px;
  color:#b4b4b4;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 52px;  
}

.nav-active{
  background:#80bd01;
}

.nav-active > .nav-links{
  color:#fff;
}