.gridWrapperStep5{
  width:100%;
  display:grid;
  grid-template-columns: 1fr 1fr;

  .sectionTitle{
    text-align:center;
    font-size: 17px;
    font-weight: 500;
  }
}
.baglantiWrapper{
  margin-top:20px;
}
.baglantiWrapper > .edits{
  margin:0px !important;
}

.contactWrapper{
  display:flex;
  width:100%;
  .edits{
    font-size:14px;
    margin-top:5px !important;
    margin-bottom:5px !important;
  }
}

.contactCheck{
  margin-right: 10px;
  width:160px;
}

.medyaDeneyimiRadioGroup{
  margin-bottom: 30px;
}

.contactWrapperInfo{
  max-width: 369px;
  width:100%;
  font-size:15px;
  margin-bottom:10px;
}
.infoAltyapi{
  font-size:15px;
  color:#666;
  line-height: 21px;;
}
.altyapiSub{
  font-size:15px;
  color:#80bd01;
  display:block;
  clear:both;
  font-weight: 500;
  margin-top:5px;
  margin-bottom:10px;
}

.groupContacts{
  display:flex;
  align-items: center;
}
.iletisimBilgileriCheckGroup{
  width:580px;
  display:flex;

  .checkContainer{
    width:auto !important;
    margin-right:10px;
  }
}
.groupContacts2{
  display: flex;
  justify-content: end;
  width: 100%;


  .contactCheck{
    width:130px;
  }

  .edits{
    max-width: 500px;
  }

}

.step5SosyalMedya{
  margin-top:15px;
  padding-top:15px;
}

.socialMediaIcon{
  
}

.socialLine{
  margin-top:5px;
  margin-bottom:5px;
}

.socialMedyaInfo{
  margin-left:5px;
  margin-top:1px;
  color:#666;
}

.onayTick{
  font-size:15px;
  margin-top:50px;
  position: relative;
  display: flex;
  font-weight: 500;
  justify-self: end;
  
}

.contactWrapper .checkContainer{
  width: auto;
  margin-bottom:0px;
  margin-right:10px;
}
.buttonBaglantiEkle{
  margin-left:5px;
  background:#80bd01;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
}

.iletisimBilgileriCheckGroupPre{
  display:flex;
}
.iletisimBilgileriCheckGroupPres{
  width:100% !important;

  .contactWrapper{
    width:100% !important;
  }
}

.medyaDeneyimiRadioGroupWrapper{
  width:100%;
  display: flex;
  justify-content: center;
}
.medyaDeneyimiRadioGroup{
  width:  100%;
  display: flex;
  justify-content: center;
}

.contactTercih{
  width:unset !important;
  display: flex;
  flex-direction: row;
  margin-bottom:40px;
}

.sectionBottom{
  margin-bottom: 20px;
}
.margin-top-x{
  margin-top:50px;
}