/* The checkContainer */
.checkContainer {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkdp{
  display:none;
  fill:#fff;
}


/* Hide the browser's default checkbox */
.checkContainer input {
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left:0px;
  width: calc(((var(--base-height-multiplier) + var(--density)) * var(--design-unit) / 2 + var(--design-unit)) * 1px);
  height: calc(((var(--base-height-multiplier) + var(--density)) * var(--design-unit) / 2 + var(--design-unit)) * 1px);
  box-sizing: border-box;
  border-radius: calc(var(--corner-radius) * 1px);
  border: calc(var(--outline-width) * 1px) solid var(--neutral-outline-rest);
  background: var(--neutral-fill-input-rest);
  outline: none;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
// .checkContainer:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #80bd01;
  .checkdp{
    display:block;
    color:#fff;
  }

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  .checkdp{
    display:block;
  }
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}