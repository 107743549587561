footer{
  display:flex;
  justify-content: space-between;
  padding-right:10%;
  padding-left:10%;
}


.nextButton{
  height:65px;
  fill:#80bd01;
}
.nextButtonDone{
  height:45px;
  fill:#80bd01;
}

.nextButtonLinkWrapper{
  display:flex;
  align-items: center;
  text-decoration: none;
}

.nextButtonLinkWrapper:hover{
  .nextButtonText{
    color:#1e5c37;
  }

  .nextButton{
    fill:#1e5c37;
  }

  .nextButtonDone{
    fill:#1e5c37; 
  }

}

.nextButtonText{
  color:#80bd01;
  text-decoration: none;
  margin-right:-15px;
  font-weight: 500;
  margin-top:-2px;
}
.nextButtonTextDone{
  margin-right:10px;
}






.backButton{
  height:65px;
  fill:#80bd01;
}
.backButtonDone{
  height:45px;
  fill:#80bd01;
}

.backButtonLinkWrapper{
  display:flex;
  align-items: center;
  text-decoration: none;
}

.backButtonLinkWrapper:hover{
  .backButtonText{
    color:#1e5c37;
  }

  .backButton{
    fill:#1e5c37;
  }

  .backButtonDone{
    fill:#1e5c37; 
  }

}

.backButtonText{
  color:#80bd01;
  text-decoration: none;
  margin-left:-15px;
  font-weight: 500;
  margin-top:-2px;
}
.backButtonTextDone{
  margin-left:10px;
}


